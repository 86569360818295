.contentFont .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: red;
}

.App-link {
  color: #61dafb;
}
.canvas-style {
  height: 77vh;
  margin-right: 2vw;
}
.timeline-date-style {
  color: #05386b;
  font-weight: bolder;
}
.tag-style {
  margin-top: 1.5vh;
}
.tag-style > * {
  margin-top: 1vh;
}
.tag-style-project > * {
  margin-right: 1vw;
  margin-top: 1vh;
}
.tag-style > div {
  margin-right: 1vw;
}
.tech-div-style {
  margin: 0;
  margin-top: 3vh;
  margin-bottom: 1vh;
}
.aws-btn {
  --slider-height-percentage: 56%;
  --slider-transition-duration: 770ms;
  --organic-arrow-thickness: 5px;
  --organic-arrow-border-radius: 0px;
  --organic-arrow-height: 25px;
  --organic-arrow-color: #26456f;
  --control-button-width: 16%;
  --control-button-height: 38%;
  --control-button-background: transparent;
  --control-bullet-color: #2d5182;
  --control-bullet-active-color: #26456f;
  --loader-bar-color: #851515;
  --loader-bar-height: 6px;
}
@media only screen and (max-width: 1169px) {
  .timeline-date-style {
    font-weight: bolder;
    color: white;
  }
  .tag-style > div {
    margin-top: 1vh;
    margin-right: 1vw;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
